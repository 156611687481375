
















import { Vue, Component, Prop } from 'vue-property-decorator';
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import { AgencyModel } from "@/api/generated";
import Api from "@/api";
import Discriminator from '../../../types/Discriminator';
import getContractType from '../../../utility/getContractType';

const snackbarModule = getModule(SnackbarModule);

@Component
export default class AgencyAutocomplete extends Vue {
  @Prop({ default: Discriminator.VESSELS }) discriminator!: Discriminator;
  
  private loading = false;
  private countries: Array<AgencyModel> = [];

  private async created() {
    try {
      this.loading = true;
      const response = await Api.AgencyService.apiAgencyDiscriminatorGet(getContractType(this.discriminator));
      this.countries = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to load agencies");
    } finally {
      this.loading = false;
    }
  }
}
