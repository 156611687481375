















import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import { VesselTypeModel } from "@/api/generated";
import Discriminator from '@/types/Discriminator';
import getContractType from '../../../utility/getContractType';

const snackbarModule = getModule(SnackbarModule);

@Component
export default class VesselTypeAutocomplete extends Vue {
  private loading = false;
  private vesselTypes: Array<VesselTypeModel> = [];
  @Prop({default: Discriminator.VESSELS}) discriminator!: Discriminator;

  private async created() {
    try {
      this.loading = true;
      const response = await Api.VesselTypeService.apiVesseltypeDiscriminatorGet(getContractType(this.discriminator));
      this.vesselTypes = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to load vessel types");
    } finally {
      this.loading = false;
    }
  }
}
