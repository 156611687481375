











































































































































import { Component, PropSync, Ref, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { VForm } from "@/types/vForm";
import Validation from "@/mixins/validation";
import Api from "@/api";
import SnackbarModule from "@/store/snackbarModule";
import { ContractType, VesselModel, VesselUpsertModel } from "@/api/generated";
import AgencyAutocomplete from "@/components/common/agency/AgencyAutocomplete.vue";
import VesselTypeAutocomplete from "@/components/common/vessel/VesselTypeAutocomplete.vue";
import UserModule from "@/store/userModule";
import VesselProfilePicture from "@/components/vessel/VesselProfilePicture.vue";
import Discriminator from '@/types/Discriminator';

const snackbarModule = getModule(SnackbarModule);
const userModule = getModule(UserModule);

@Component({
  components: {
    AgencyAutocomplete,
    VesselTypeAutocomplete,
    VesselProfilePicture
  }
})
export default class VesselEdit extends Validation {
  @Ref() private form!: VForm;
  @Prop(String) vesselId!: string;

  get discriminator() {
    return Discriminator.LAND_WORKERS;
  }

  private loading = false;
  private vessel: VesselModel = {
    name: "",
    rss: "",
    contactName: "",
    telephoneNumber: "",
    emailAddress: "",
    contactPostalAddress: "",
    discriminator: ContractType.NUMBER_1
  };

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }

  get getVesselProfilePicture() {
    return this.vessel.vesselPictureId;
  }

  private async created() {
    await this.getVessel();
  }

  private async getVessel() {
    try {
      const response = await Api.VesselService.apiVesselVesselIdGet(
        this.vesselId
      );
      this.vessel = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to retrieve company");
    }
  }

  private async onVesselUpdate() {
    if (!this.form.validate()) {
      return;
    }

    try {
      this.loading = true;
      await Api.VesselService.apiVesselVesselIdPut(this.vesselId, this.vessel);
      snackbarModule.setSnackbarMessage("Company updated");
      this.$emit("vessel-updated");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to update company");
    } finally {
      this.loading = false;
    }
  }
}
